import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/banner', data)
}
export function getAdd(data) {
    return post('admin/banner/add', data)
}
export function getDel(data) {
    return post('admin/banner/del', data)
}
export function getEdit(data) {
    return post('admin/banner/edit', data)
}
export function getOption(data) {
    return post('admin/banner/cat', data)
}