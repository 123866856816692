import {post} from '@/utils/request'

//轮播图分类列表
export function getBannerCatList(data) {
  return post('admin/bannerCat/list',data)
}
//轮播图分类添加
export function getBannerCatAdd(data) {
    return post('admin/bannerCat/add',data)
  }
  //轮播图分类删除
export function getBannerCatDel(data) {
    return post('admin/bannerCat/del',data)
  }
    //轮播图分类编辑
export function getBannerCatEdit(data) {
  return post('admin/bannerCat/edit',data)
}