<template>
  <div class="user">
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加轮播图</div>
        <div class="title" v-show="!openState">修改轮播图</div>
      </div>
      <el-form :model="form">
        <el-form-item
          label="分类"
          :label-width="formLabelWidth"
          v-if="!openState"
        >
          <el-select
            v-model="form.cat_id"
            style="width: 100%"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="form.title" placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.sort" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item label="跳转地址" :label-width="formLabelWidth">
          <el-input v-model="form.url" placeholder="兑换券名称"></el-input>
        </el-form-item>
        <el-form-item label="APP ID" :label-width="formLabelWidth">
          <el-input v-model="form.app_id" placeholder="APP ID"></el-input>
        </el-form-item>
         <el-form-item label="页面地址" :label-width="formLabelWidth">
            <el-select v-model="form.path_arr" multiple placeholder="请选择">
    <el-option
      v-for="item in optionss"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
        </el-form-item>
        <el-form-item label="上传轮播图" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="file"
            :file-list="fileList"
            :data="imgParam"
          >
            <el-button size="small" type="primary">上传轮播图</el-button>
          </el-upload>
        </el-form-item>
       
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">删除轮播图</div>
      </div>
      <div style="margin-bottom: 10px">{{ userdelinfo.title }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="catalogue"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addOpen()">添加</el-button>
        </el-col>
        <!-- <el-col :span="12">
          <div class="border">
            <div
              @click="changeState(item)"
              v-for="item in list"
              :key="item.name"
              class="border-item"
              :class="[item.id == getInfo.cat_id ? 'active' : '']"
            >
              {{ item.name }}
            </div>
          </div>
        </el-col> -->
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
      <el-table-column type="expand">
          <template slot-scope="props">
            <el-tag
              style="margin-right: 10px; margin-top: 5px"
              v-for="(item, index) in props.row.data"
              :key="index"
              >{{ item.name }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="url" label="图片">
          <template slot-scope="scope">
            <div class="image">
              <img
                :src="scope.row.img"
                alt=""
                width="120"
                v-if="scope.row.img"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div class="block">
              <el-tag type="success" v-if="scope.row.status">显示</el-tag>
              <el-tag type="success" v-else>隐藏</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="delOpen(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.list_num"
        background
        :total="exp.total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getList, getEdit, getAdd, getDel, getOption } from "@/api/banner.js";
import {
  getBannerCatList
} from "@/api/bannerCart.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
          optionss: [],
      token: {
        token: window.sessionStorage.getItem("token"),
      },
      imgParam: {
        path: "banner",
      },
      dialogLog: {
        state: false,
      },
      spacer: "\u00a0\u00a0\u00a0",
      openState: true,
      //配置
      optionProps: {
        checkStrictly: true,
        value: "id",
        label: "name",
        children: "son",
      },
      //总页数
      exp: {
        total: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        cat_id: 1,
        info: "",
        page: 1,
        list_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        title: "",
        type: "1",
        img: "",
        url: "",
        sort: "",
        status: "",
        app_id: "",
        cat_id: "",
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
      fileList: [],
      state: "0",
      list: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeState(item) {
      this.getInfo.cat_id = item.id;
      this.catalogue();
    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      this.form.img = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.img = "";
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.list_num = value;
      this.catalogue();
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page = value;
      this.catalogue();
    },
    //打开添加
    addOpen() {
      this.openState = true;
      this.dialogFormVisible = true;
      this.form.cat_id = this.getInfo.cat_id;
    },
    //打开编辑
    editOpen(row) {
      console.log(row);
      this.form = row;
      this.openState = false;
      this.dialogFormVisible = true;
      this.fileList = [{ name: row.title, url: row.img }];
    },
    //编辑打开
    async setEdit() {
      let info = await getEdit(this.form);
      this.judge(info);
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true;
      this.userdelinfo = row;
    },
    // 删除
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
      });
      this.judge(info);
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      let info = await getAdd(this.form);
      this.judge(info);
    },
    judge(info) {
      if (info.data.code == 1) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dialogVisible = false;
        this.catalogue();
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        title: "",
        type: "1",
        img: "",
        url: "",
        sort: "",
        status: "",
        cat_id: "",
      };
      (this.fileList = []), console.log("close");
    },
    //获取轮播图分类列表
    async getBannerCatList(data) {
      let res = await getBannerCatList(data);
      if (res.data.code == 1) {
        this.optionss=res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    //目录接口
    async catalogue() {
      let info = await getList(this.getInfo);
      console.log(info.data);
      this.tableData = info.data.data.data;
      this.exp.total = info.data.data.total;
      let options = await getOption();
      this.options = options.data.data;
      this.list = options.data.data;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
      //获取轮播图分类列表
     this.getBannerCatList();
    this.catalogue();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.border {
  display: flex;
}
.border-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  color: #666;
  margin-right: 5px;
}
.active {
  color: #909399 !important;
  background: #f4f4f5 !important;
}
.image {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #c0c4cc;
  overflow: hidden;
}
</style>